/* Global Styles */
.globalStyle *::before,
.globalStyle *::after {
  box-sizing: border-box;
}

.globalStyle body,
.globalStyle html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; 
}

.brandsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); 
  gap: 20px;
  align-items: start;
  background: #1c1c1c;
  color: white;
  padding: 20px;
  font-family: Arial, sans-serif;
}

@media (max-width: 1024px) {
  .brandsContainer {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
  }
}

@media (max-width: 768px) {
  .brandsContainer {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
  }
}

.brandCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #292929;
  border-radius: 8px;
}


.heading {
  grid-column: 1 / -1;
  text-align: center;
  color: white;
  margin-bottom: 20px;
}


.brandName {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.description {
  text-align: center;
  font-size: 1rem;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .description {
    font-size: 0.9rem;
  }
}

.brandImage {
  height: 30px;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

@media (max-width: 768px) {
  .brandImage {
    height: 20px;
  }
}
