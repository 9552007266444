.banner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff; /* Set your desired background color */
    padding: 10px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .banner {
      flex-direction: column;
    }
  }
  
  .badgeItem {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .styledBannerImage {
    width: 200px; /* Adjust as needed */
    height: auto;
    margin-bottom: 20px;
  }
  
  @media (max-width: 700px) {
    .styledBannerImage {
      width: 120px; /* Adjust as needed */
    }
  }
  
  .styledBannerImageSecond {
    width: 220px; /* Slightly bigger than the other items */
  }
  
  @media (max-width: 700px) {
    .styledBannerImageSecond {
      width: 140px; /* Adjust as needed for smaller screens */
    }
  }
  
  .badgeText {
    color: #333; /* Set your desired text color */
    font-size: 14px; /* Adjust as needed */
    width: 200px; /* Adjust as needed for your layout */
    text-align: center; /* Center align the text */
    margin: 0; /* Remove default margin */
    line-height: 1.6; /* Adjust line height for better readability */
    word-break: break-word; /* Ensure long words break to avoid overflow */
    padding-bottom: 20px; /* Reduce padding on smaller screens */
  }
  
  @media (max-width: 768px) {
    .badgeText {
      font-size: 1rem; /* Optionally reduce the font size on smaller screens */
    }
  }
  
  .middleText {
    font-size: 1.1rem; /* Optionally reduce the font size on smaller screens */
    color: #333; /* Choose a professional color, darker shades tend to look more professional */
    max-width: 1500px; /* Adjust the width to contain your text nicely */
    margin: 0 auto; /* Center the text box */
    padding: 0px 20px; /* Add some padding to create space around the text */
    padding-bottom: 20px;
    line-height: 1.6; /* This affects the spacing between lines */
    font-weight: normal; /* Adjust the font weight as needed */
    text-align: center; /* Center align the text */
  }
  
  @media (max-width: 768px) {
    .middleText {
      padding: 10px; /* Reduce padding on smaller screens */
      font-size: 1rem; /* Optionally reduce the font size on smaller screens */
    }
  }
  