/* VideoSection.module.css */

:root {
    --green-color: green;
  }

  .sectionWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
  }
  
  .textColumn {
    text-align: center;
    width: 60%;
    padding-bottom: 40px;
  }
  
  @media (max-width: 1200px) {
    .textColumn {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  
  .videoColumn {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }
  
  @media (min-width: 1200px) {
    .videoColumn {
      width: var(--video-size, 100%);
    }
  }
  
  @media (min-width: 1700px) {
    .videoColumn {
      width: var(--videoSize, 85%);
    }
  }
  
  .styledIframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  .subheading {
    font-size: 2.5rem;
  }
  
  @media (max-width: 768px) {
    .subheading {
      font-size: 2rem;
      padding-top: 15px;
      white-space: pre-wrap;
    }
  }
  
  .descText {
    font-size: 1.1rem;
    line-height: 1.6;
    font-weight: normal;
  }
  
  @media (max-width: 768px) {
    .descText {
      font-size: 1rem;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
    }
  }
  
  .logo {
    width: 400px;
    margin-top: 40px;
  }
  
  @media (max-width: 1000px) {
    .logo {
      width: 350px;
    }
  }
  
  @media (max-width: 768px) {
    .logo {
      width: 300px;
    }
  }
  
  @media (max-width: 700px) {
    .logo {
      width: 150px;
    }
  }
  
  .styledButton {
    display: inline-block;
    margin-top: 40px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: var(--green-color);
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
  
  .styledButton:hover {
    background-color: #0056b3;
  }
  
  .styledButton:focus {
    outline: none;
  }
  