.missionSection {
    background-color: #f4f4f4;
  }
  
  .upperRow {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 768px) {
    .upperRow {
      flex-direction: column;
    }
  }
  
  .missionContent {
    flex: 0.5;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;               /* Use Flexbox for the content */
    flex-direction: column;      /* Arrange children in a column */
    justify-content: center;     /* Center content vertically */
    align-items: center;         /* Center content horizontally */
    text-align: center;          /* Ensure text is centered */
}

.missionTitle, .missionText {
    margin: 0; /* Remove any default margins */
}

  
  @media (max-width: 1099px) {
    .missionContent {
      padding: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .missionContent {
      padding: 20px;
    }
  }
  
  .missionTitle {
    color: black;
    font-size: 2.5rem;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .missionTitle {
      font-size: 2rem;
    }
  }
  
  .missionText {
    color: black;
    text-align: center;
    font-size: 1.1rem;
  }
  
  @media (max-width: 768px) {
    .missionText {
      font-size: 1rem;
    }
  }
  
  .videoContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    padding-bottom: 38.25%;
    height: 0;
    overflow: hidden;
  }

  @media (max-width: 300px) {
    .videoContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
    }
  }

  
  @media (max-width: 500px) {
    .videoContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
    }
  }
  
    @media (max-width: 769px) {
    .videoContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
    }
  }

     @media (max-width: 800px) {
    .videoContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
    }
  }
  
  
  
  .styledIframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  .styledMissionImage {
    width: 100%;
    height: auto;
    transform: rotate(90deg);
    object-position: center;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .styledMissionImage {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  
  .missionPoints {
    display: flex;
    margin-top: -2rem;
  }
  
  @media (max-width: 768px) {
    .missionPoints {
      flex-direction: column;
    }
  }
  
  .pointThree {
    flex: 1;
    background-size: cover;
    background-position: center;
    padding: 20px;
    text-align: center;
  }
  
  .point {
    flex: 1;
    text-align: center;
    padding: 20px;
  }
  
  .pointTitle {
    font-size: 2.2rem;
    color: black;
  }
  
  @media (max-width: 768px) {
    .pointTitle {
      font-size: 2rem;
    }
  }
  
  .pointDescription {
    font-size: 1.1rem;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .pointDescription {
      font-size: 1rem;
    }
  }
  
  .pointImage {
    width: 100%;
    margin-left: -20px;
    height: auto;
  }
  
  .pointImageTwo {
    width: calc(100% + 40px);
    margin-left: -20px;
    height: auto;
    display: block;
  }
  
  .dark {
    color: white;
    background-color: #78bc44;
  }
  
  .pointThree.dark {
    color: white;
    background-image: url("https://i.imgur.com/uUu5J7n.jpeg");
  }
  