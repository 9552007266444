.carousel-container .react-multi-carousel-item {
  transition: transform 0.5s ease; /* Smooth transition for transform */
}

.carousel-item-padding-40-px {
  padding: 0 10px; /* Space between items */
}
/* Base styles for all images */
.image-item img {
  width: 100%;  /* Ensures the image fills the container */
  height: 300px; /* Default height */
  object-fit: cover; /* Ensures the image covers the area without distorting */
}

/* Responsive adjustments */
@media (max-width: 1024px) { /* Tablet and below */
  .image-item img {
      height: 200px; /* Smaller height for smaller screens */
  }
}

@media (max-width: 464px) { /* Mobile */
  .image-item img {
      height: 200px; /* Even smaller height for mobile screens */
  }
}
