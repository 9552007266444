/* Global Styles */
.globalStyle *::before,
.globalStyle *::after {
  box-sizing: border-box;
}

.globalStyle body,
.globalStyle html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Component Styles */
.sectionWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .sectionWrapper {
    height: 650px;
  }
}

.textColumn {
  text-align: center;
  padding-top: 150px;
  padding-bottom: 30px;
  width: 80%;
}

@media (max-width: 300px) {
  .textColumn {
    width: 100%;
    padding-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
}
@media (max-width: 400px) {
  .textColumn {
    width: 100%;
    padding-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
}

.videoColumn {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .videoColumn {
    width: var(--videoSize, 100%);
  }
}

@media (min-width: 1700px) {
  .videoColumn {
    width: var(--videoSize, 85%);
  }
}

.styledIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.styledImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.heading {
  font-size: 1.8rem;
  padding-top: 20px;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.0rem;
    padding-top: 100px;
  }
}

.subheading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .subheading {
    font-size: 2rem;
  }
}

.descText {
  font-size: 1.2rem;
  line-height: 1.6;
  font-weight: normal;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .descText {
    font-size: 1rem;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.greenText {
  color: #78bc44;
}
