.navbarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between logos and hotline number */
    width: 100%;
    height: 60px;
    background: linear-gradient(to right, black, green 50%);
    position: fixed;
    top: 0;
    z-index: 10;
    padding: 10px 10px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 40px;
  }
  
  @media(max-width: 768px) {
    .logo {
      height: 20px; 
    }
  }
  
  .logoo {
    height: 40px;
    margin-left: -25px;
  }
  
  @media(max-width: 768px) {
    .logoo {
      height: 20px; 
    }
  }
  
  .centerText {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: white;
  }
  
  .centerText a {
    color: white;
    text-decoration: none;
  }
  
  .centerText a:hover {
    opacity: 1;
  }
  
  @media(max-width: 768px) {
    .centerText {
      font-size: 0.7em;
    }
  }
  
  .hotlineNumber {
    display: flex;
    align-items: center;
    background-color: green;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-size: 1em;
    margin-left: 20px;
  }
  
  @media(max-width: 768px) {
    .hotlineNumber {
      font-size: 0.9em;
      padding: 5px 10px;
    }
  }
  
  .icon {
    margin-right: 5px;
  }
  