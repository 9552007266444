.sectionWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .sectionWrapper {
      height: auto;
    }
  }
  
  .textColumn {
    text-align: center;
    padding-bottom: 30px;
  }
  
  @media (max-width: 768px) {
    .textColumn {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 30px;
    }
  }
  
  .imageColumn {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    overflow: hidden;
  }
  
  .imageColumn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .imageColumn img {
    position: relative;
    z-index: 0;
  }
  
  .styledImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .subheading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .subheading {
      font-size: 2rem;
    }
  }
  
  .descText {
    font-size: 1.1rem;
    line-height: 1.6;
    font-weight: normal;
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .descText {
      font-size: 1rem;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  
  .downloadButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: green;
    border-radius:5px;
    font-size: 1rem;
    color: white;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    z-index: 2;
  }
  
  .downloadButton:hover {
    opacity: 1;
  }
  