/* SideBySideImageText.module.css */

.wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .wrapper {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .textContainer {
    width: 60%;
    text-align: center;
    font-size: 1.1rem;
  }
  
  @media (max-width: 768px) {
    .textContainer {
      width: 100%;
      font-size: 1rem;
      white-space: pre-wrap;
    }
  }
  
  .textHeader {
    font-size: 2.5rem;
  }
  
  @media (max-width: 768px) {
    .textHeader {
      font-size: 2rem;
    }
  }
  
  .textBody {
    word-wrap: break-word;
  }
  
  @media (max-width: 768px) {
    .textBody {
      padding-bottom: 20px;
    }
  }
  
  .imageWrapper {
    justify-content: center;
    align-items: center;
    width: 40%;
    display: flex;
    padding-top: 50px;
  }
  
  @media (max-width: 768px) {
    .imageWrapper {
      padding-top: 0px;
    }
  }
  
  .image {
    width: 250px;
  }
  
  .imageFullWidth {
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .image {
      width: 100%;
    }
  }
  